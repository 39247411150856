// Types used for filters standardized across web and studio frontend
import { amenityTags, communityTypeTags } from '@tmap/mmm-core/sanity/tags';
import { getDescendantProfessions } from '@tmap/mmm-core/professions/taxonomy';

import {
  AttachMoney,
  CardGiftcard,
  FilterHdr,
  FlightTakeoff,
  PriceChangeOutlined,
  AssignmentInd,
  PedalBike,
  Attractions,
  ChevronRight,
  Laptop,
  GroupWork,
} from '@tmap/mmm-style-guide/src/Icon';

export const offerTypes = [
  { title: 'Cash', value: 'cash', icon: AttachMoney },
  { title: 'Free Gift', value: 'gift', icon: CardGiftcard },
  { title: 'Land', value: 'land', icon: FilterHdr },
  { title: 'Relocation Reimbursement', value: 'relocationReimbursement', icon: FlightTakeoff },
  { title: 'Loan Payment', value: 'studentLoan', icon: PriceChangeOutlined },
  { title: 'Tax Credit', value: 'taxCredit', icon: AttachMoney },
  { title: 'Membership', value: 'membership', icon: AssignmentInd },
  { title: 'Special Amenity', value: 'specialAmenity', icon: PedalBike },
  { title: 'Experience', value: 'experience', icon: Attractions },
  { title: 'Other', value: 'other', icon: ChevronRight },
];
export const offerTypeFromValue = (value) => (
  offerTypes.find((m) => m.value === value) || offerTypes[0]
);

export const regionTypes = [
  {
    title: 'West Coast',
    value: 'westCoast',
    children: [
      { title: 'Alaska', value: 'AK' },
      { title: 'California', value: 'CA' },
      { title: 'Hawaii', value: 'HI' },
      { title: 'Oregon', value: 'OR' },
      { title: 'Washington', value: 'WA' },
    ],
  },
  {
    title: 'Rocky Mountains',
    value: 'rockyMountains',
    children: [
      { title: 'Colorado', value: 'CO' },
      { title: 'Idaho', value: 'ID' },
      { title: 'Montana', value: 'MT' },
      { title: 'Nevada', value: 'NV' },
      { title: 'Utah', value: 'UT' },
      { title: 'Wyoming', value: 'WY' },
    ],
  },
  {
    title: 'Great Plains',
    value: 'greatPlains',
    children: [
      { title: 'Kansas', value: 'KS' },
      { title: 'Nebraska', value: 'NE' },
      { title: 'North Dakota', value: 'ND' },
      { title: 'Oklahoma', value: 'OK' },
      { title: 'South Dakota', value: 'SD' },
    ],
  },
  {
    title: 'Midwest',
    value: 'midwest',
    children: [
      { title: 'Illinois', value: 'IL' },
      { title: 'Indiana', value: 'IN' },
      { title: 'Iowa', value: 'IA' },
      { title: 'Michigan', value: 'MI' },
      { title: 'Minnesota', value: 'MN' },
      { title: 'Missouri', value: 'MO' },
      { title: 'Ohio', value: 'OH' },
      { title: 'Wisconsin', value: 'WI' },
    ],
  },
  {
    title: 'Mid-Atlantic',
    value: 'midAtlantic',
    children: [
      { title: 'Delaware', value: 'DE' },
      { title: 'Maryland', value: 'MD' },
      { title: 'New Jersey', value: 'NJ' },
      { title: 'New York', value: 'NY' },
      { title: 'Pennsylvania', value: 'PA' },
    ],
  },
  {
    title: 'New England',
    value: 'newEngland',
    children: [
      { title: 'Connecticut', value: 'CT' },
      { title: 'Maine', value: 'ME' },
      { title: 'Massachusetts', value: 'MA' },
      { title: 'New Hampshire', value: 'NH' },
      { title: 'Rhode Island', value: 'RI' },
      { title: 'Vermont', value: 'VT' },
    ],
  },
  {
    title: 'South',
    value: 'south',
    children: [
      { title: 'Alabama', value: 'AL' },
      { title: 'Arkansas', value: 'AR' },
      { title: 'Florida', value: 'FL' },
      { title: 'Georgia', value: 'GA' },
      { title: 'Kentucky', value: 'KY' },
      { title: 'Louisiana', value: 'LA' },
      { title: 'Mississippi', value: 'MS' },
      { title: 'North Carolina', value: 'NC' },
      { title: 'South Carolina', value: 'SC' },
      { title: 'Tennessee', value: 'TN' },
      { title: 'Virginia', value: 'VA' },
      { title: 'West Virginia', value: 'WV' },
    ],
  },
  {
    title: 'Southwest',
    value: 'southwest',
    children: [
      { title: 'Arizona', value: 'AZ' },
      { title: 'New Mexico', value: 'NM' },
      { title: 'Texas', value: 'TX' },
    ],
  },
  {
    title: 'Caribbean',
    value: 'caribbean',
  },
];

export const locationTypes = [
  { title: 'City', value: 'urban' },
  { title: 'Suburban', value: 'suburban' },
  { title: 'Town', value: 'town' },
  { title: 'Rural', value: 'rural' },
];

export const communitySorts = [
  { value: 'alphabetical', label: 'Alphabetical' },
  { value: 'new', label: 'New' },
  { value: 'popularity', label: 'Popularity', default: true },
  { value: 'programValue', label: 'Program Value' },
  { value: 'coli', label: 'Cost of Living' },
];

export const jobTypes = [
  {
    value: 'varied',
    label: 'Varied Roles',
  },
  {
    value: 'nurses',
    label: 'Nurses',
    taxonomyIds: [
      '29-1140',
      '29-1170',
    ],
  },
  {
    value: 'policeOfficers',
    label: 'Police Officers',
    taxonomyIds: getDescendantProfessions('33-3050'),
  },
  {
    value: 'teachers',
    label: 'Teachers',
    taxonomyIds: getDescendantProfessions('25-0000'),
  },
];

export const remoteOrInPerson = [
  { value: 'remote', label: 'Remote', icon: Laptop },
  { value: 'inperson', label: 'In-Person', icon: GroupWork },
];

export const communityFilters = [
  {
    name: 'relocationProgram',
    shortName: 'relocation',
    label: 'Has Relocation Program',
    description: 'Communities that offer incentives and networking for new residents.',
    options: { boolean: true },
  },
  {
    name: 'quickApply',
    shortName: 'quickApply',
    label: 'Quick Apply',
    description: 'Get pre-approved for these relocation programs in one business day.',
    options: { boolean: true },
  },
  {
    name: 'region',
    shortName: 'rz',
    label: 'Region',
    options: {
      array: regionTypes,
      multiple: true,
      childFilters: {
        name: 'state',
        shortName: 'st',
      },
    },
  },
  {
    name: 'regionType',
    shortName: 'rt',
    label: 'Region Type',
    options: { array: locationTypes, multiple: true },
  },
  {
    name: 'attributes',
    shortName: 'tags',
    label: 'Community Attributes',
    options: { array: [...amenityTags, ...communityTypeTags], multiple: true },
  },
];

export const offerSorts = [
  { value: 'alphabetical', label: 'Alphabetical' },
  { value: 'new', label: 'New' },
  { value: 'popularity', label: 'Popularity', default: true },
  { value: 'programValue', label: 'Program Value' },
  { value: 'coli', label: 'Cost of Living' },
];

export const offerFilters = [
  {
    name: 'offerType',
    shortName: 'ot',
    label: 'Incentive Type',
    options: { array: offerTypes, multiple: true },
  },
  {
    name: 'jobType',
    shortName: 'jt',
    label: 'Job Type',
    options: { array: jobTypes, multiple: true },
  },
  {
    name: 'remote',
    shortName: 'rm',
    label: 'Remote or In-Person',
    options: { array: remoteOrInPerson, multiple: true, variant: 'tile' },
  },
  {
    name: 'region',
    shortName: 'rz',
    label: 'Region',
    options: {
      array: regionTypes,
      multiple: true,
      childFilters: {
        name: 'state',
        shortName: 'st',
      },
    },
  },
];
