/**
 * Function to find the feature name of the element that was clicked, for use in GA feature tracking
 * @param { object } event
 * - SyntheticBaseEvent / DOM click event
 * @param { Map } elementsToTrack
 * - The list of elements that require special tracking in GA, along with the feature name for each
 * @param { string } defaultBase
 * - The default name of the base element for the component
 */
const findImpressionFeature = (event, elementsToTrack, defaultBase = 'base') => {
  const nativeEvent = event.nativeEvent || event;
  const eventPath = nativeEvent.composedPath();
  const featureName = eventPath.reduce((accum, element) => (
    accum || elementsToTrack.get(element)
  ), false);

  return featureName || defaultBase;
};

export default findImpressionFeature;
