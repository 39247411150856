import React, { useMemo, createContext, forwardRef } from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Chip } from '@tmap/mmm-style-guide/src/Chip';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Card } from '@tmap/mmm-style-guide/src/Card';
import stopEvent from '@tmap/mmm-style-guide/src/util/stopEvent';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import { useRouter } from 'next/router';
import { DARK_THEME } from '../lib/themes';

import ConditionalLazyLoad from './conditional-lazy-load';

import FavoriteButton from './favoriteButton';

const StyleContext = createContext();

const StyledCard = styled(Card, {
  shouldForwardProp: (p) => !['variant', 'cursor'].includes(p),
})(({ theme, variant, cursor }) => ({
  position: 'relative',
  background: variant === 'raised' || variant === 'contained' ? theme.palette.common.white : 'transparent',
  borderRadius: variant === 'raised' || variant === 'contained' ? '8px' : '0px',
  cursor,
  '& img, picture': {
    display: 'block',
  },
}));
const InvisibleLink = styled('a')(() => ({
  display: 'contents',
}));
const CardContentAlignment = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));
const CardContentAlignTop = styled('div')(() => ({
  position: 'relative',
  width: '100%',
}));
const CardContentAlignBottom = styled('div')(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  marginTop: theme.spacing(0.75),
}));

const LinkCard = forwardRef((props, ref) => {
  const {
    children,
    variant = 'flush',
    href,
    alt,
    ...otherProps
  } = props;
  const router = useRouter();
  const handleClick = (e) => {
    if (e.button === 0 && !e.metaKey && !e.ctrlKey && !e.shiftKey && href) {
      stopEvent(e);
      router.push(href);
    } // else browser handles it; allows cmd/ctrl/middle/shift
    // click to be used to open in new tab/window
  };
  const styleContext = useMemo(() => ({ variant, href, alt }), [variant, href, alt]);

  return (
    <StyledCard elevation={variant === 'raised' ? 2 : 0} variant={variant} cursor={href ? 'pointer' : ''} {...otherProps} ref={ref}>
      <InvisibleLink href={href} aria-label={alt} onClick={handleClick}>
        <CardContentAlignment>
          <StyleContext.Provider value={styleContext}>
            {children}
          </StyleContext.Provider>
        </CardContentAlignment>
      </InvisibleLink>
    </StyledCard>
  );
});

const LinkCardImageWrapper = forwardRef((props, ref) => {
  const { children } = props;
  return (
    <CardContentAlignTop {...props} ref={ref}>
      {children}
    </CardContentAlignTop>
  );
});

const LinkCardFooter = forwardRef((props, ref) => {
  const { children } = props;
  return (
    <StyleContext.Consumer>
      {() => (
        <CardContentAlignBottom {...props} ref={ref}>
          {children}
        </CardContentAlignBottom>
      )}
    </StyleContext.Consumer>
  );
});

const OverlayGrid = styled(Grid)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: '1',
}));
const LinkCardOverlays = forwardRef((props, ref) => {
  const { children } = props;
  return (
    <OverlayGrid container {...props} ref={ref}>
      <ThemeProvider theme={DARK_THEME}>
        {children}
      </ThemeProvider>
    </OverlayGrid>
  );
});
const PillGrid = styled(Grid)(() => ({
  // makes it wrap before favorite btn
  maxWidth: 'calc(100% - 64px)',
}));
const LinkCardPillBar = forwardRef((props, ref) => {
  const { children } = props;
  return (
    <PillGrid item>
      <Grid container spacing={0.5} {...props} ref={ref}>
        {children}
      </Grid>
    </PillGrid>
  );
});
const LinkCardPill = forwardRef(({ sx, ...props }, ref) => (
  <Grid item>
    <Chip variant='pill' size='small' sx={{ opacity: 0.95, ...sx }} {...props} ref={ref} />
  </Grid>
));

const LinkCardFavoriteButton = forwardRef(({ entity, ...props }, ref) => (
  <Grid item xs>
    <Grid container justifyContent='flex-end'>
      <Grid item ref={ref}>
        <FavoriteButton entity={entity} Button={IconButton} color='inverted' {...props} />
      </Grid>
    </Grid>
  </Grid>
));

const FavoriteBackground = styled('div')(() => ({
  borderRadius: 'inherit',
  '&::before': {
    content: '""',
    borderRadius: 'inherit',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'radial-gradient(circle at top right, rgba(31,31,31,0.3) 0% 5%, rgba(31,31,31,0) 25%)',
  },
}));
const VerticalOverlayBackground = styled('div', {
  shouldForwardProp: (p) => !['top', 'bottom'].includes(p),
})(({ top, bottom }) => ({
  borderRadius: 'inherit',
  '&::before': {
    content: '""',
    borderRadius: 'inherit',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: (() => {
      if (top && bottom) return 'linear-gradient(180deg, rgba(31,31,31,0.4), rgba(31,31,31,0.1), transparent 50%, rgba(31,31,31,0.1), rgba(31,31,31,0.4))';
      if (top) return 'linear-gradient(180deg, rgba(31,31,31,0.4), rgba(31,31,31,0.1), transparent 50% 100%)';
      if (bottom) return 'linear-gradient(180deg, transparent 0% 50%, rgba(31,31,31,0.1), rgba(31,31,31,0.4))';
      return 'none';
    })(),
  },
}));
const LinkCardImage = forwardRef((props, ref) => {
  const {
    shouldLazyLoad = false,
    lazyProps = {},
    dimBottom,
    dimTop,
    dimFavorite,
    borderRadius,
    children,
    ...otherProps
  } = props;

  const backgrounds = [];
  if (dimFavorite) backgrounds.push({ component: FavoriteBackground });
  if (dimTop || dimBottom) {
    backgrounds.push(
      { component: VerticalOverlayBackground, props: { top: dimTop, bottom: dimBottom } },
    );
  }

  const BackgroundWrapper = backgrounds.reduce((Accum, { component: Component, props }) => (
    function ({ children }) {
      return <Accum><Component {...props}>{children}</Component></Accum>;
    }
  ), ({ children }) => <>{children}</>);

  const smushBottomCorners = (variant) => (
    variant === 'raised' || variant === 'contained' ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {}
  );

  return (
    <StyleContext.Consumer>
      {({ variant }) => (
        <Grid
          item
          xs={12}
          sx={{ borderRadius, ...smushBottomCorners(variant) }}
          {...otherProps}
          ref={ref}
        >
          <BackgroundWrapper>
            <ConditionalLazyLoad style={{ borderRadius: 'inherit' }} shouldLazyLoad={shouldLazyLoad} {...lazyProps}>
              {children}
            </ConditionalLazyLoad>
          </BackgroundWrapper>
        </Grid>
      )}
    </StyleContext.Consumer>
  );
});

LinkCard.ImageWrapper = LinkCardImageWrapper;
LinkCard.Footer = LinkCardFooter;
LinkCard.Overlays = LinkCardOverlays;
LinkCard.PillBar = LinkCardPillBar;
LinkCard.Pill = LinkCardPill;
LinkCard.FavoriteButton = LinkCardFavoriteButton;
LinkCard.Image = LinkCardImage;

export default LinkCard;
